<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 17">
        <path stroke="currentColor" stroke-linecap="round" stroke-width="1.5"
            d="M1 11v4c0 .5523.44772 1 1 1h14c.5523 0 1-.4477 1-1v-4"
        />
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
            d="M9.5 1v10m0 0L12 8.22222M9.5 11 7 8.22222"
        />
    </svg>
)
</script>

<style lang="scss" scoped>

</style>
i
